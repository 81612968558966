import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Footer from "../../Footer/FooterBottom";
import history from '../../../history';
import Backgrounds from "../../Admin/Question/View/Background";
import ComingSoon from "../ComingSoon";
import api from "../../../api/api";

class Subscription extends React.Component {

    state = { showOffer: false, minutes: 0 };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        if (this.props.user &&
            this.props.user.user &&
            this.props.user.user.IsActive &&
            this.props.user.user.UserType.Id === 2 &&
            this.props.location && this.props.location.location &&
            this.props.location.location.country_code !== "il"
        ) {
            const duration = moment.duration(moment().diff(moment(this.props.user.user.CreationDate)));
            const days = duration.asDays().toFixed();
            const hours = (duration.asHours() % 24).toFixed();
            const minutes = (duration.asMinutes() % 60).toFixed();

            if (days <= 0 && hours <= 0 && minutes < 15) {
                this.state = {
                    showOffer: true,
                    minutes
                };
            }

        } else {
            history.push("/Profile");
        }
    }

    componentDidMount() {
        window.scrollToPosition("main-renwal-section");
    }

    stop = () => {
        this.setState({
            showOffer: false
        });
    }

    getVisaLink() {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.get(`/PayProglobalPayment/`).then(response => {
            window.location.href = response.data;
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        toast.dismiss(this._toastId.current);
    }

    render() {
        const { t, user } = this.props;

        return <>
            <Helmet>
                <title>
                    {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} |  {t('Pages.Profile.services.title')}
                </title>
            </Helmet>
            <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% );overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;}.footer-bottom{display:none;}` }}></style>

            <section className="pos-rel call-in-action call-overlay oh" id="main-renwal-section" style={{
                backgroundColor: '#75cdff'
            }}>

                <div className="position-relative padding-bottom w-100 d-flex align-items-center justify-content-center" style={{
                    minHeight: '100vh'
                }}>
                    <Backgrounds CustomBackground="Registration" />
                    <div className="container h-100" >
                        <div className="widget widget-category col-xl-10 mx-auto">
                            <div className="widget-header p-3 d-flex justify-content-center align-items-center">
                                <LazyLoadImage src="/assets/images/logo/logo-1.png" alt={`${t('alarabeyya')}`} />
                            </div>
                            <div className="widget-body">


                                <div className="font-weight-bold h4 mt-3 d-flex flex-wrap align-items-center w-100  justify-content-center">
                                    سعر الاشتراك :
                                    {
                                        this.state.showOffer ?
                                            <>
                                                <span className="mx-2 ">

                                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                                        <span className="h1 cursor-pointer">
                                                            147
                                                            <i className="fal fa-dollar-sign"></i>
                                                        </span>
                                                        <span className="h4 cursor-pointer" style={{
                                                            textDecoration: 'line-through #f00 double'
                                                        }}>
                                                            297
                                                            <i className="fal fa-dollar-sign"></i>
                                                        </span>
                                                    </div>

                                                </span>
                                                <span className="d-flex align-items-center mx-2">
                                                    إذا بدأت اليوم تحصل على خصم
                                                    <span className="h3 cursor-pointer my-0 mx-1">
                                                        150
                                                        <i className="fal fa-dollar-sign"></i>
                                                    </span>
                                                </span>
                                            </>
                                            :
                                            <span className="cursor-pointer">
                                                297
                                                <i className="fal fa-dollar-sign"></i>
                                            </span>
                                    }
                                </div>


                                {
                                    this.state.showOffer ?
                                        <ComingSoon date={moment().add(this.state.minutes, 'minutes')} stop={this.stop} />
                                        : ""
                                }

                                <div className="list w-100 text-right">
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                            إمكانيّة تحميل أيّ ملفّ ورقيّ من الموقع لمدة سنة .
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                            فتح كلّ الدروس المحوسبة لمدّة سنة .
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                            فتح كلّ الألعاب المحوسبة لمدّة سنة .
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                            فتح جميع محتويات الموقع لمدّة سنة .
                                        </li>
                                    </ul>
                                </div>

                                <div className="d-flex w-100 flex-wrap justify-content-center">
                                    <Link to="#" onClick={() => this.getVisaLink()} className={`custom-button primary-button m-3`} >
                                        <span>
                                            اشترك الأن&nbsp;
                                            {
                                                this.state.showOffer ?
                                                    <>
                                                        واحصل على خصم 50%
                                                    </>
                                                    : ""
                                            }
                                        </span>
                                    </Link>
                                    <Link to="/Profile" className={`custom-button danger-button m-3`} >
                                        <span>
                                            انتقل للموقع لبدء التجربة المجانية
                                        </span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-sticky">
                <Footer />
            </div>
        </>;
    }

}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(Subscription));