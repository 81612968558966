import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Footer from "../../Footer/FooterBottom";
import Backgrounds from "../../Admin/Question/View/Background";

import fileImg from "./file.png";
import examVideo from "./2.mp4";
import gameVideo from "./3.mp4";

class Registration extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Register.title')}  | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel call-in-action call-overlay oh" id="register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    backgroundAttachment: 'fixed',
                    minHeight: '100vh'
                }}>
                    <div className="position-relative padding-bottom pt-4" style={{
                        minHeight: '100vh'
                    }}>
                        <span className="d-none d-md-block">
                            <Backgrounds CustomBackground="Registration" />
                        </span>
                        <div className="container" >
                            <div className="teacher-wrapper pos-rel pt-md-5 pt-sm-2 rounded main-bg">
                                <div className=" teacher-single-content px-1 d-flex flex-column pt-3">
                                    <h3 className="title text-center font-weight-bold h2">
                                        اُحصُلْ على أكثر 200 مادة تعليميّة مجّانًا
                                    </h3>
                                    <div className="w-100 text-center mt-3 h5 p-3 rounded" style={{
                                        background: '#ffffff80'
                                    }}>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            الموقع الأول من نوعه لتعليم اللغة العربية بالشرق الأوسط (ملفات ورقية\ محوسبة وألعاب تعليمية).
                                        </p>
                                    </div>
                                    <div className="form-group w-100 text-center d-flex flex-column justify-content-center align-items-center mt-3 order-sm-3 order-md-2">
                                        <Link to="/Prices" className={`custom-button`} >
                                            <span className="d-flex justify-content-center align-items-center flex-wrap">
                                                اِبدأْ تجربتك المجّانيّة الآن
                                                <i className="icon fad fa-arrow-left px-2"></i>
                                            </span>
                                        </Link>
                                        <div className="title mt-3" style={{
                                            fontSize: '18px'
                                        }}>
                                            التسجيل يستغرق أقلّ مِن 60 ثانية
                                            ولا يوجد حاجة لإدخال بطاقة اعتماد
                                            .
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row clearfix justify-content-center mt-4">
                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-download display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            تمَّ تَحمِيل 612،538 +
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            قام مُستخدمو هيّا إلى العربيّة بتحميل أكثر من 600 ألف ملفّ.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-users display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            200 ألف مُستخدِم
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            عدد مُستخدمي المواقع التعليميّة التابعة لنا أكثر من 200 ألف مستخدِم.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-globe display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            24 دولة
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            لدينا زبائن في أكثر من 24 دولة حول العالم.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-presentation-screen display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            2500 ملف+
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            ستجد أكثر من 2500 ملفّ، بالإضافة إلى الملفّات التي نضيفها شهريًّا.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="teacher-wrapper pos-rel mt-4 rounded pt-md-5 pt-sm-2" style={{
                                background: '#fff8eb'
                            }}>
                                <div className=" teacher-single-content p-3 ">
                                    <h3 className="title text-center mb-3">
                                        #1
                                        أوراق عمل وامتحانات
                                    </h3>
                                    <h4 className="title text-center">
                                        " ممكن طباعتها وتحميلها"
                                    </h4>
                                    <img src={fileImg} className="w-100" alt="img" />
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            موقع "هيّا إلى العربيّة" يحتوي على أكثر من
                                            <span className=" font-weight-bold mx-1">
                                                2500 ملفّ
                                            </span>
                                            (
                                            أوراق عمل وامتحانات
                                            )
                                            قابلة للتحميل في&nbsp;
                                            <span className=" font-weight-bold">
                                                جميع مواضيع اللغة العربيّة.
                                            </span>
                                            (كلُّ موضوعٍ قد يخطر على بالك للمرحلتين الابتدائيّة والإعداديّة مُتوفّر داخل الموقع).
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            طبعًا مع الأخذ بالحسبان مواضيع مناسبة لجميع الفئات والمجتمعات.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="font-weight-bold">
                                            باختصار، بَدلًا من حرق ساعات من حياتك في تجهيز الوظائف والامتحانات وأوراق العمل، يمكنك ببساطة تحميل الملفات بنقرة زر.
                                        </p>
                                    </div>



                                    <h3 className="title text-center mb-3 mt-5">
                                        #2
                                        أَلعَاب تعْليميَّة
                                    </h3>

                                    <video className="w-100" controls muted>
                                        <source src={examVideo} type="video/mp4" />
                                    </video>
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            موقع "هيّا إلى العربيّة" يحتوي على ألعاب تعليميّة
                                            <span className=" font-weight-bold mx-1">
                                                قمنا ببرمجتها من الصفر وغير متوفّرة في أيّ موقع آخر
                                            </span>.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            هذه الألعاب حصريّة لموقع "هيّا إلى العربيّة" ومن المستحيل أن تجدها في أيّ مكان آخر على الإنترنتّ؛ والسبب بكلّ بساطة هو أنّنا قمنا ببنائها من الصفر، ابتداءً من الفكرة وصولًا إلى البرمجة.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="font-weight-bold">
                                            وصول موقع "هيّا إلى العربيّة" للمستوى الذي هو عليه اليوم، احتاج منّا أكثر من 10 سنوات عمل وأكثر من 5 مليون دولار.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            لهذا السبب، من شبه المستحيل أن تقوم الشركات الصغيرة بتقليد الأمور التي نقدمها.
                                        </p>
                                    </div>

                                    <h3 className="title text-center my-3">
                                        #3
                                        دروس محوسبة وامتحانات
                                    </h3>

                                    <video className="w-100" controls muted>
                                        <source src={gameVideo} type="video/mp4" />
                                    </video>
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            الملفّات المحوسبة هي امتحانات وأوراق عمل يمكن حلّها عن طريق الكمبيوتر، حيث يستطيع الابن التعلّم بمفرده ويقوم الموقع بالتصحيح تلقائيًّا، ممّا يساعد المعلّم أو وليّ الأمر على اختصار وقت فحص الإجابات من عدَّةِ ساعات إلى عدّةِ ثوانٍ.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="font-weight-bold">
                                            باختصار، الموقع يُقدِّم قائمةً تحتوي على الإجابات الصحيحة والمغلوطة، بالإضافة إلى كشف علامة الطالب بعد ثوانٍ من إنهاء ورقة العمل أو الامتحان المحوسب.
                                        </p>
                                    </div>


                                </div>
                            </div>

                            <div className="teacher-wrapper pos-rel mt-4 rounded p-2 pt-md-3 pt-sm-2" style={{
                                background: '#fff8eb'
                            }}>
                                <div className="about-content mb-3">
                                    <div className="faq-wrapper mb--10">

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل احتاج بطاقة اعتماد للتسجيل بالتجربة المجانية ؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    لا تحتاج لإدخال بطاقة اعتماد للتسجيل بالتجربة المجانية ، كل الذي عليك فعله للتسجيل بالتجربة المجانية هو النقر على ( ابدأ تجربتك المجانية الآن) ومن ثم اختيار اسم مستخدم وكلمة مرور والبدء بالاستمتاع بتجربتك المجانية
                                                    .
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل توجد مواد ملائمة لجميع المستويات؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، الموقع مُقسّم حسب الصفوف، ولكل صفّ برنامج منفرد يتضمّن مواد مختلفة تتناسب مع المستوى التعليميّ لكل صف.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    ما هي الأمور التي احصل عليها خلال التجربة المجانية ؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    سوف تحصل على أكثر 200 مادة تعليميّة مجّانًا وتشمل اكثر من 100 ملف محوسب و اكثر من 50 درس مجاني في جميع المراحل وتشمل اكثر 50 امتحان محوسب واكثر من 20 لعبة مجانية.
                                                    <span className="font-weight-bold mx-1">
                                                        التسجيل يستغرق 60 ثانية ولا يوجد حاجة لإدخال بطاقة اعتماد.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    ما هو سعر الاشتراك بالموقع وماذا يشمل في حال قررت الاشتراك ؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    سعر الاشتراك الفردي بالموقع هو 297$.
                                                    <br />
                                                    <div className="list w-100 text-right">
                                                        هذا المبلغ يشمل :
                                                        <ul>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                إمكانيّة تحميل أيّ ملفّ ورقيّ من الموقع لمدة سنة .
                                                            </li>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                فتح كلّ الدروس المحوسبة لمدّة سنة.
                                                            </li>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                فتح كلّ الألعاب المحوسبة لمدّة سنة.
                                                            </li>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                فتح جميع محتويات الموقع لمدّة سنة.
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </p>
                                            </div>
                                        </div>


                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يوجد ضمان (كيف أتأكّد أنّ الموقع مناسب لاحتياجاتي)؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    بكلّ بساطة، يمكنك التسجيل في التجربة المجّانيّة وتجربة الموقع بنفسك. في حال لم تحبّ الموقع أو لم تجده مفيدًا، يمكنك إلغاء  (مع أنّ هذا الاحتمال نادر جدًا، فلو لم يكن الموقع مفيدًا لما انتشر بشكل كبير ووصل إلى 24 دولة حتى الآن).
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يمكن إرسالُ مهامٍّ محوسبة للأولاد؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، يمكن إرسالُ مهامٍّ محوسبة لكلّ طلاب الصفّ أو جزء منهم، لكن هذه الخاصيّة متوفّرة فقط في اشتراكات المدارس وليست في الاشتراكات الفرديّة.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    ما الفرق بين الاشتراك الفرديّ واشتراك المدارس؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    الاشتراك الفرديّ يسمح بدخول شخص واحد فقط للموقع، بينما في اشتراك المدارس يمكنك فتح حساب لكل طاقم اللغة العربية، بالإضافة إلى تحميل جميع أسماء الطلاب وفتح حساب منفرد لكل طالب، وهذا يمنحك إمكانيّة إرسال مهامّ محوسبة مباشرة للطلاب.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يستطيع عدّة معلّمين الدخول إلى الموقع بنفس الوقت من حساب المدرسة؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، بالتأكيد. يحصل كلّ معلّم على اسم مستخدم وكلمة مرور مختلفة، ممّا يعني أنّ حساب كلّ معلّم يكون منفصلًا تمامًا عن باقي المعلّمين في المدرسة.
                                                </p>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="form-group w-100 text-center d-flex flex-column justify-content-center align-items-center mt-5 order-sm-3 order-md-2">
                                    <Link to="/Prices" className={`custom-button`} >
                                        <span className="d-flex justify-content-center align-items-center flex-wrap">
                                            اِبدأْ تجربتك المجّانيّة الآن
                                            <i className="icon fad fa-arrow-left px-2"></i>
                                        </span>
                                    </Link>
                                    <div className="title mt-3" style={{
                                        fontSize: '18px'
                                    }}>
                                        التسجيل يستغرق أقلّ مِن 60 ثانية
                                        ولا يوجد حاجة لإدخال بطاقة اعتماد
                                        .
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section >
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Registration));